@font-face {
	font-family: "montserrat-regular";
	src: url("/static/font/montserrat/montserrat-regular-webfont.woff2") format("woff2"),
		url("/static/font/montserrat/montserrat-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "montserrat-bold";
	src: url("/static/font/montserrat/montserrat-bold-webfont.woff2") format("woff2"),
		url("/static/font/montserrat/montserrat-bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
