:root {
	--color-primary-400: #4c56ff;
	--color-primary-500: #3732ff;
	--color-primary-600: #211db6;

	// footer
	--color-background-footer: var(--color-primary-400);
	--color-text-footer: white;

	// active color
	--color-active: var(--color-primary-500);

	// input-label
	--input-label-active-color: var(--color-primary-500);
}
