@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.footer {
	&__contact {
		.phone-number-sva {
			@include gridle_state(md) {
				margin: 0 0 10px;
			}
		}

		.footer-contact {
			.help-desk-phone-number {
				margin-top: 10px;
			}

			.phone-number-sva {
				@include gridle_state(md) {
					margin: 0 0 10px;
				}
			}
		}
	}
}
